<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'New Address': 'Nova carteira',
      'Default Address': 'Endereço Padrão',
      'Address is required.': 'Endereço é obrigatório.',
      'Send': 'Cadastrar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.' : 'Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.',
      'We sent an email to confirm your new Tether address.': 'Enviamos um e-mail para confirmar seu novo endereço de Tether.'
    },
    es: {
      'New Address': 'Nueva billetera',
      'Default Address': 'Endereço Padrão',
      'Address is required.': 'Endereço é obrigatório.',
      'Send': 'Registrar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.' : 'Lo sentimos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'We sent an email to confirm your new Tether address.': 'Enviamos un correo electrónico para confirmar su nueva dirección de Tether.'
    }
  },
  components: { Layout, VclList },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      table: {
        tether: {
          heade: [
            'Date', 'Address', 'Status'
          ],
          body: null,
          loading: true,
          errored: false,
          empty: false,
        }
      },

      alert: {
        tether: { type: '', message: '' },
        tetherModal: { type: '', message: '' }
      },

      tether: { modal: false, default: null, address: "" },
    };
  },
  validations: {
    tether: {
      address: { required }
    },
  },
  methods: {
    getTether() {
      this.table.tether.body = null

      this.table.tether.loading = true
      this.table.tether.errored = false
      this.table.tether.empty = false

      api
        .get('wallet/tether')
        .then(response => {
          if (response.data.status=='success') {
            this.tether.default = response.data.default
            this.table.tether.body = response.data.list
          } else {
            this.table.tether.body = null
          }
        })
        .catch(error => {
          this.table.tether.errored = error
        })
        .finally(() => {
          this.table.tether.loading = false
          if (this.table.tether.body=='' || this.table.tether.body==null) {
            this.table.tether.empty = true
          }
        })
    },
    tetherSubmit() {
      this.$v.tether.$touch();

      if (this.tether.address) {
        api
          .post('wallet/tether', {
            address: this.tether.address,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.tether.address = ''
              this.$v.tether.$reset()

              this.alert.tether.type = 'alert-success'
              this.alert.tether.message = response.data.message

              this.tether.modal = false
              this.tether.loading = true
              this.getTether()
            } else {
              this.alert.tetherModal.type = 'alert-danger'
              this.alert.tetherModal.message = response.data.message
            }
          })
      }
    },
  },
  mounted() {
    this.getTether()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Tether') }}</h4>
          <div class="page-title-right">
            <button
              type="button"
              class="btn btn-default btn-sm text-uppercase"
              @click="tether.modal = true"
            >{{ t('New Address') }}</button>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div v-if="alert.tether.message" :class="'alert ' + alert.tether.type">{{ t(alert.tether.message) }}</div>
            <div v-if="tether.default" class="pt-3 pb-0 pl-3 p-r">
              <h4 class="card-title mb-0">{{ t('Default Address') }}</h4>
              <p class="card-title-desc">{{ this.tether.default }}</p>
            </div>
            <div v-if="table.tether.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.tether.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.tether.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.tether.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.tether.body" :key="index">
                    <td v-for="(data, row) in td" :key="row">
                      <div v-if="row === 'status'">
                        <span
                          v-if="data === 'CONFIRMING'"
                          class="badge badge-soft-info font-size-12"
                          >AGUARDANDO CONFIRMAÇÃO</span
                        >
                        <span
                          v-else-if="data === 'INACTIVE'"
                          class="badge badge-soft-warning font-size-12"
                          >INATIVO</span
                        >
                        <span
                          v-else-if="data === 'ACTIVE'"
                          class="badge badge-soft-success font-size-12"
                          >ATIVO</span
                        >
                      </div>
                      <div v-else>
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="tether.modal" :title="t('New Address')" centered>
      <p>Cadastre somente uma carteira USDT.TRC20 da rede Tron/TRC20</p>
      <b-form @submit.prevent="tetherSubmit">
        <div v-if="alert.tetherModal.message" :class="'alert ' + alert.tetherModal.type">{{ t(alert.tetherModal.message) }}</div>
        <b-form-group id="tether-address" :label="t('Address')" label-for="tether-address">
          <b-form-input id="tether-address" v-model="tether.address" type="text" :class="{ 'is-invalid': $v.tether.address.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.tether.address.$error" class="invalid-feedback">
            <span v-if="!$v.tether.address.required">{{ t('Address is required.') }}</span>
          </div>
        </b-form-group>
        <b-button :disabled="!this.tether.address" type="submit" variant="default">{{ t('Send') }}</b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>